<template>
  <div>
    <div class="page-header" style="margin-bottom: 24px">
      <div class="page-header__wrapper">
        <div class="page-header__left">
          <h3 class="title">Distributers</h3>
        </div>
        <div>
          <el-dropdown style="margin-right: 12px">
            <el-button type="primary" size="medium">
              Bulk Toggle Template<i
                class="el-icon-arrow-down el-icon--right"
              ></i>
            </el-button>
            <el-dropdown-menu slot="dropdown" class="customDropDown">
              <el-dropdown-item command="true">
                <el-button
                  type="primary"
                  icon="el-icon-top"
                  size="mini"
                  @click="exportBulkToggleTemplate('create')"
                >
                  Create Template
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item command="true">
                <el-button
                  type="primary"
                  icon="el-icon-top"
                  size="mini"
                  @click="exportBulkToggleTemplate('update')"
                >
                  Update Template
                </el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <el-button
            size="medium"
            type="primary"
            icon="el-icon-bottom"
            @click="exportBulkToggleTemplate"
            :loading="downloadingExcelFile"
          >
            Download Bulk Toggle Template
          </el-button> -->
          <el-button
            size="medium"
            type="primary"
            icon="el-icon-top"
            @click="showUploadDialog = true"
          >
            Upload Toggle Sheet
          </el-button>
          <el-button type="info" size="medium" @click="exportDistributers">{{
            $t("DistributerPageExportDistributersBtn")
          }}</el-button>
          <el-button
            class="c-distributers-header__button"
            size="medium"
            icon="el-icon-plus"
            type="success"
            plain
            @click="dialogFormVisible = true"
          >
            {{ $t("DistributerPageAddNewProviderBtn") }}
          </el-button>
        </div>
      </div>
    </div>

    <!-- Add New Dis Dialog -->
    <el-dialog
      :title="$t('DistributerPageAddDistributerDialogTitle')"
      width="80%"
      @closed="onDialogClose"
      :visible.sync="dialogFormVisible"
    >
      <el-form
        ref="addDistributerForm"
        :model="addDistributer"
        :rules="addDistributerRules"
      >
        <el-form-item
          :label="$t('DistributerPageAddDistributerDialogUserNameLabel')"
          :label-width="formLabelWidth"
          prop="userName"
        >
          <el-input
            v-model="addDistributer.userName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Type ID"
          :label-width="formLabelWidth"
          prop="typeId"
        >
          <el-select v-model="addDistributer.typeId" @change="typeIdChanged">
            <div>
              <el-option
                key="nationalId"
                label="National ID"
                value="nationalId"
              ></el-option>
              <el-option key="taxId" label="Tax ID" value="taxId"></el-option>
            </div>
          </el-select>
        </el-form-item>
        <template v-if="addDistributer.typeId === 'nationalId'">
          <div>
            <el-row gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="National Name"
                  :label-width="formLabelWidth"
                  prop="nationalName"
                >
                  <el-input
                    v-model="addDistributer.nationalName"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="National Id"
                  :label-width="formLabelWidth"
                  prop="nationalId"
                >
                  <el-input
                    v-model="addDistributer.nationalId"
                    @input="limitInputToMaxDigits(14, 'nationalId')"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </template>
        <template v-if="addDistributer.typeId === 'taxId'">
          <div>
            <el-row gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="Tax Name"
                  :label-width="formLabelWidth"
                  prop="taxName"
                >
                  <el-input
                    v-model="addDistributer.taxName"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="Tax Id"
                  :label-width="formLabelWidth"
                  prop="taxId"
                >
                  <el-input
                    v-model="addDistributer.taxId"
                    @input="limitInputToMaxDigits(9, 'taxId')"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </template>

        <el-form-item
          :label="$t('DistributerPageAddDistributerDialogStoreNameLabel')"
          :label-width="formLabelWidth"
          prop="name"
        >
          <el-input v-model="addDistributer.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('DistributerPageAddDistributerDialogPhone')"
          :label-width="formLabelWidth"
          prop="phone"
        >
          <el-input
            v-model="addDistributer.phone"
            autocomplete="off"
            maxlength="11"
            show-word-limit
            type="number"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('DistributerPageAddDistributerDialogTypeLabel')"
          :label-width="formLabelWidth"
          prop="provider"
        >
          <el-select v-model="addDistributer.provider">
            <div v-if="providesActivityTypeArray">
              <el-option
                v-for="entityType in providesActivityTypeArray"
                :key="entityType.id"
                :label="entityType.name"
                :value="entityType.id"
              ></el-option>
            </div>
            <div v-else>
              <el-option
                key="distributer"
                label="distributer"
                value="distributer"
              ></el-option>
              <el-option
                key="supplier"
                label="supplier"
                value="supplier"
              ></el-option>
            </div>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('DistributerPageAddDistributerDialogAddressLabel')"
          :label-width="formLabelWidth"
          prop="address"
        >
          <el-input
            v-model="addDistributer.address"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('DistributerPageAddDistributerDialogNotesLabel')"
          :label-width="formLabelWidth"
          prop="note"
        >
          <el-input
            v-model="addDistributer.notes"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('DistributerPageAddDistributerDialogPasswordLabel')"
          :label-width="formLabelWidth"
          prop="password"
        >
          <el-input
            v-model="addDistributer.password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('DistributerPageAddDistributerDialogArea')"
          :label-width="formLabelWidth"
          prop="areaId"
        >
          <el-select v-model="addDistributer.areaId">
            <div v-if="areasLookups">
              <el-option
                v-for="areaObject in areasLookups"
                :key="areaObject.key"
                :label="areaObject.value"
                :value="areaObject.key"
              ></el-option>
            </div>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="Area" prop="Area" :label-width="formLabelWidth">
            <div class="u-display-flex">
              <el-select
                v-if="lookups && lookups.areas"
                clearable
                class="u-right-margin--2x u-flex-shrink--0"
                v-model="tempAreaPriceObject.area"
                placeholder="Please select an area"
              >
                <el-option
                  v-for="(area, i) in lookups.areas"
                  :key="i"
                  :label="area.value"
                  :value="area.key"
                >
                </el-option>
              </el-select>
              <el-form-item
                v-if="tempAreaPriceObject.area"
                :label-width="formLabelWidth"
                label="minimum order price"
              >
                <el-input
                  class="u-right-margin--2x"
                  type="number"
                  v-model="tempAreaPriceObject.price"
                  placeholder="Please enter the minmum order price"
                  autocomplete="off"
                />
              </el-form-item>

              <el-button
                class="u-right-margin--2x"
                v-if="tempAreaPriceObject.area"
                :disabled="
                  !tempAreaPriceObject.area || !tempAreaPriceObject.price
                "
                type="primary"
                @click="assignAreaToProvider"
              >
                Add
              </el-button>
            </div>
          </el-form-item> -->
        <!-- <div v-if="providerAreas.length">
            <el-table :data="providerAreas" style="width: 100%">
              <el-table-column label="اسم المنطقة">
                <template slot-scope="scope">
                  {{ getAreaNameById(scope.row.id) }}
                </template>
              </el-table-column>
              <el-table-column prop="amount" label="الحد الأدنى للطلبات">
              </el-table-column>
              <el-table-column>
                <template slot-scope="scope">
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    plain
                    @click="removeAreaFromProvider(scope.row.id)"
                  >
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">{{
          $t("DistributerPageAddDistributerDialogCancelBtn")
        }}</el-button>
        <el-button
          type="primary"
          :loading="providerCreateLoading"
          @click="createProvider"
        >
          {{ $t("DistributerPageAddDistributerDialogConfirmBtn") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- Add New Dis Dialog -->
    <!-- Upload Dialog -->
    <el-dialog title="Upload Sheet" :visible.sync="showUploadDialog">
      <el-upload
        action="#"
        accept=".xlsx"
        :multiple="false"
        :auto-upload="false"
        :file-list="excelFileList"
        :on-change="uploadFileChange"
        :on-remove="removeFile"
      >
        <el-button slot="trigger" size="mini" type="primary">
          Select
        </el-button>
        <el-button
          :disabled="excelFileList.length == 0"
          size="mini"
          plain
          icon="el-icon-top"
          type="info"
          :loading="uploadingExcelFile"
          @click="uploadSet"
        >
          Import
        </el-button>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showUploadDialog = false">Close</el-button>
      </span>
    </el-dialog>
    <!-- /Upload Dialog -->

    <div class="transactions-list-filter">
      <el-select
        v-model="filters.type"
        :class="$store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'"
        @change="filtersChanged('type', $event)"
        size="normal"
      >
        <el-option key="all" label="all" :value="undefined"> </el-option>
        <el-option
          v-for="p in providesActivityTypeArray"
          :key="p.name"
          :label="p.name"
          :value="p.name"
        >
          <!--<span style="float: left">{{ p.name }}</span>
           <span style="float: right; color: #8492a6; font-size: 13px">{{
            p.tooltip
          }}</span> -->
        </el-option>
      </el-select>
      <el-input
        v-model="filters.id"
        :placeholder="$t('DistributerPageFilterIDPlaceholder')"
        style="width: 200px"
        size="normal"
        :class="$store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'"
        clearable
        @change="filtersChanged('id', $event)"
      ></el-input>

      <el-input
        v-model="filters.name"
        :placeholder="$t('DistributerPageFilterNamePlaceholder')"
        style="width: 200px"
        size="normal"
        :class="$store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'"
        clearable
        @change="filtersChanged('name', $event)"
      ></el-input>
      <el-select
        class="area-dropdown"
        v-if="lookups"
        :class="$store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'"
        v-model="filters.area"
        filterable
        multiple
        :placeholder="$t('DistributerPageFilterAreaPlaceholder')"
        @change="filtersChanged('area', $event)"
      >
        <el-option
          v-for="(item, key) in lookups.areas"
          :key="key"
          :label="item.value"
          :value="item.key"
        >
        </el-option>
      </el-select>
      <span>
        <dropdownCategoriesSelect
          v-model="filters.region"
          @change="filtersChanged('region', $event)"
        />

        <el-radio-group
          v-model="filters.hasImage"
          @change="filtersChanged('hasImage', $event)"
        >
          <el-radio-button :label="true"
            >{{ $t("ProductsPageFilterImageRadioHasImagelabel") }}
          </el-radio-button>
          <el-radio-button :label="false">
            {{
              $t("ProductsPageProductsPageFilterImageRadioHasDefaultImageLabel")
            }}
          </el-radio-button>
          <el-radio-button :label="null">
            {{ $t("ProductsPageFilterImageRadioHasNoImageLabel") }}
          </el-radio-button>
        </el-radio-group>
      </span>
    </div>
    <div class="c-distributers-body">
      <el-table :data="providersList" border style="width: 100%">
        <el-table-column
          prop="id"
          :label="$t('DistributerPageIDcolumnTitle')"
          width="50"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          :label="$t('DistributerPageNameColumnTitle')"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="minOrder"
          :label="$t('DistributerPageMinimumOrderColumnTitle')"
          width="220"
        >
        </el-table-column>
        <el-table-column
          prop="createdAt"
          :label="$t('DistributerPageJoinedAtColumnTitle')"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="displayOrder"
          :label="$t('DistributerPageDisplayOrderColumnTitle')"
          width="200"
        >
          <template slot-scope="scope">
            <div>
              <el-input
                v-model="scope.row.displayOrder"
                placeholder=""
                size="mini"
                clearable
                @change="OrderChange(scope.row.id, $event)"
              ></el-input>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="providerType"
          :label="$t('DistributerPageTypeColumnTitle')"
          width="200"
        >
        </el-table-column>
        <el-table-column :label="$t('DistributerPageOperationsColumnTitle')">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="info"
              icon="el-icon-user"
              plain
              @click="viewDistributerProtofolio(scope.row)"
            >
              {{ `View Product Portfolio` }}
            </el-button>

            <el-button
              size="mini"
              type="info"
              icon="el-icon-user"
              plain
              @click="viewDistributerProfile(scope.row)"
            >
              {{ $t("DistributerPageViewProfileBtn") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="u-display-flex u-justify-content--center u-top-padding--2x">
      <el-pagination
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        :total="pagination.totalResultsCount"
        :current-page="paginationCurrentPage"
        :page-size="50"
        @current-change="handlePagination"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import dropdownCategoriesSelect from "@/components/dropdownCategoriesSelect";
import { providersService } from "../services/providers.service";
import { utils } from "../utils/index";

export function generateValidationRules(maxCharacters, fieldLabel) {
  return [
    {
      required: false,
      message: `${fieldLabel} is required`,
      trigger: "blur",
    },
    {
      max: maxCharacters,
      message: `${fieldLabel} cannot exceed ${maxCharacters} characters`,
      trigger: "blur",
    },
  ];
}

export default {
  name: "Distributers",
  components: { dropdownCategoriesSelect },
  data() {
    return {
      uploadingExcelFile: false,
      excelFileList: [],
      showUploadDialog: false,
      downloadingExcelFile: false,
      pagination: {},

      providersTypes: [
        { label: "All", value: undefined, tooltip: "الكل" },
        { label: "Suppliers", value: "supplier", tooltip: "الشركات" },
        { label: "Distributers", value: "distributer", tooltip: "تجار الجمله" },
      ],
      providersList: [],
      filters: {
        type: undefined,
        activity: ["provides"],
      },
      dialogFormVisible: false,
      addDistributer: {
        // provider: "distributer",
      },
      addDistributerRules: {
        name: [
          {
            required: true,
            message: this.$t(
              "DistributerPageAddDistributerDialogInputStoreNameValidation"
            ),
            trigger: "blur",
          },
        ],
        nationalName: generateValidationRules(250, "National Name"),
        taxName: generateValidationRules(250, "Tax Name"),
        nationalId: [
          {
            required: false,
            message: this.$t("National ID is required"),
            trigger: "blur",
          },
          {
            pattern: /^\d{14}$/, // Regular expression for 14 digits
            message: "National ID must be a 14-digit number",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Password is required",
            trigger: "blur",
          },
          {
            // pattern: /^\d{8}$/,
            min: 8,
            message: "Password must be a 8-digit character minimum",
            trigger: "blur",
          },
        ],
        taxId: [
          {
            required: false,
            message: this.$t("Tax ID is required"),
            trigger: "blur",
          },
          {
            pattern: /^\d{9}$/, // Regular expression for 14 digits
            message: "Tax ID must be a 9-digit number",
            trigger: "blur",
          },
        ],
        userName: [
          {
            required: true,
            message: this.$t(
              "DistributerPageAddDistributerDialogInputUserName"
            ),
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: this.$t(
              "DistributerPageAddDistributerDialogInputStorePhoneValidation"
            ),
            trigger: "blur",
          },
        ],
        typeId: [
          {
            required: false,
            message: "Please Select The Type Id",
            trigger: "change",
          },
        ],
        provider: [
          {
            required: true,
            message: "Please Select The Distributer Type",
            trigger: "change",
          },
        ],
      },
      formLabelWidth: "200px",
      providerCreateLoading: false,
      providerAreas: [],
      tempAreaPriceObject: {},
    };
  },
  async mounted() {
    this.filters = JSON.parse(sessionStorage.getItem("filter")).providers;

    if (
      this.providesActivityTypeArray &&
      this.providesActivityTypeArray.length > 0
    ) {
      if (!(this.filters && this.filters.type)) {
        this.filters.type = this.providesActivityTypeArray[0].name;
      }
    }

    await this.getProviders();
  },

  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    paginationCurrentPage() {
      return this.pagination.nextPage
        ? this.pagination.nextPage - 1
        : this.pagination.totalPages;
    },
    providesActivityTypeArray() {
      let currentLookups =
        this.lookups && this.lookups.entity_types
          ? this.lookups.entity_types.filter(
              (singleType) =>
                singleType.activity && singleType.activity == "provides"
            )
          : [];
      return currentLookups;
    },
    areasLookups() {
      let currentAreasLookups =
        this.lookups && this.lookups.areas ? this.lookups.areas : [];
      return currentAreasLookups;
    },
  },

  methods: {
    uploadFileChange(file) {
      // Allow only one file in the file list not multiple
      this.excelFileList = [file];
    },
    removeFile() {
      this.excelFileList = [];
    },
    async uploadSet() {
      let form = new FormData();
      form.append("file", this.excelFileList[0].raw);
      try {
        let response = await providersService.importBulkToggleSheet(form);
        if (response && response.data) {
          const responseData = response.data;
          let defaultType = "application/json; charset=utf-8";
          let responseContentType = response.headers["content-type"];
          if (defaultType === responseContentType) {
            let decodedString = String.fromCharCode.apply(
              null,
              new Uint8Array(responseData)
            );
            let parsedResponse = JSON.parse(decodedString);
            if (parsedResponse.message) {
              this.$message({
                message: parsedResponse.message,
                type: "success",
                duration: 2000,
              });
            } else {
              this.$message({
                message: "Success",
                type: "success",
                duration: 2000,
              });
            }
          } else {
            let blob = new Blob([responseData], {
              type: response.headers["content-type"],
            });
            let downloadedFileName = "uploadErrorSheet";
            utils.downloadBlob(blob, downloadedFileName, "xlsx");
            // this.$refs["sheet-download"].setAttribute(
            //   "href",
            //   window.URL.createObjectURL(blob)
            // );
            // this.$refs["sheet-download"].setAttribute(
            //   "download",
            //   `uploadErrorSheet.xlsx`
            // );
            // this.$refs["sheet-download"].click();
            this.$message({
              message:
                "file uploaded successfully, please check the downloaded file",
              type: "success",
              duration: 4000,
            });
          }
        }
        this.showUploadDialog = false;
      } catch (err) {
        this.$message({
          message: "Error when importing excel file",
          type: "error",
          duration: 2000,
        });
      } finally {
        this.uploadingExcelFile = false;
        this.excelFileList = [];
      }
    },
    async exportBulkToggleTemplate(action) {
      try {
        let response = await providersService.exportBulkToggleTemplate(action);
        if (response && response.data) {
          const responseData = response.data;
          let blob = new Blob([responseData], {
            type: response.headers["content-type"],
          });
          let downloadedFileName = `LiveStatusTemplate-${action}`;
          utils.downloadBlob(blob, downloadedFileName, "xlsx");
          this.$message({
            message: "file downloaded successfully",
            type: "success",
            duration: 4000,
          });
        }
      } catch (error) {
        this.$message({
          message: "Error when exporting template excel file",
          type: "error",
          duration: 2000,
        });
      } finally {
        this.downloadingExcelFile = false;
      }
    },
    typeIdChanged() {
      // Reset validation rules based on selected typeId
      if (this.addDistributer.typeId === "taxId") {
        this.$refs["addDistributerForm"].clearValidate("nationalName");
        this.$refs["addDistributerForm"].clearValidate("nationalId");
        // this.$refs.addDistributerForm.validate();
      } else if (this.addDistributer.typeId === "nationalId") {
        this.$refs["addDistributerForm"].clearValidate("taxName");
        this.$refs["addDistributerForm"].clearValidate("taxId");
        // this.$refs.addDistributerForm.validate();
      }
    },
    limitInputToMaxDigits(maxDigits, inputName) {
      // Remove any non-numeric characters
      this.addDistributer[inputName] = this.addDistributer[inputName].replace(
        /\D/g,
        ""
      );
      // Limit to 14 digits
      if (this.addDistributer[inputName].length > maxDigits) {
        this.addDistributer[inputName] = this.addDistributer[inputName].slice(
          0,
          maxDigits
        );
      }
    },
    async OrderChange(id, order) {
      try {
        await providersService.updateProviderProfile({
          providerId: id,
          data: { displayOrder: order },
        });
        this.getProviders();
      } catch (err) {
        console.log(err);
      }
    },
    onDialogClose() {
      this.providerAreas = [];
      this.$refs["addDistributerForm"].resetFields();
    },
    async getProviders(page = 1) {
      const filters = this.filters;
      this.$loading();
      return providersService
        .listAllProviders(page, filters)
        .then((res) => {
          this.providersList = res.providersList;
          this.pagination = res.pagination;
          this.$loading().close();
        })
        .finally(() => {
          this.$loading().close();
        });
    },
    async exportDistributers() {
      this.$loading();
      try {
        let distibutersResponse = await providersService.getProvidersCsv(
          this.paginationCurrentPage,
          {
            filter: this.filters,
            format: "csv",
          }
        );

        utils.downloadCSV(distibutersResponse.data, `distributers`);
      } catch (err) {
        console.log("******************ERORR*********************");
        console.log(err);
      } finally {
        this.$loading().close();
      }
    },

    viewDistributerProfile(distributerObject) {
      this.$router.push({
        name: "distributerProfile",
        params: { id: distributerObject.id },
      });
    },

    viewDistributerProtofolio(distributerObject) {
      this.$router.push({
        name: "distributerDetails",
        params: { id: distributerObject.id },
      });
    },

    assignAreaToProvider() {
      let isAreaAlreadyAdded = _.find(this.providerAreas, {
        id: this.tempAreaPriceObject.area,
      });

      if (isAreaAlreadyAdded) {
        isAreaAlreadyAdded.amount = this.tempAreaPriceObject.price;
      } else {
        this.providerAreas.push({
          id: this.tempAreaPriceObject.area,
          amount: this.tempAreaPriceObject.price,
        });
      }

      this.tempAreaPriceObject = {};
    },

    removeAreaFromProvider(selectedAreaId) {
      this.providerAreas = _.reject(this.providerAreas, { id: selectedAreaId });
    },

    getAreaNameById(areaId) {
      let area = _.find(this.lookups.areas, { key: areaId });

      return area.value;
    },
    generateDisObjToSent(data) {
      if (data.typeId === "nationalId") {
        return {
          ...data,
          taxId: undefined,
          taxName: undefined,
        };
      }
      return {
        ...data,
        nationalId: undefined,
        nationalName: undefined,
      };
    },

    async createProvider() {
      const objToSent = this.generateDisObjToSent(this.addDistributer);
      this.$refs["addDistributerForm"].validate((valid) => {
        if (valid) {
          this.providerCreateLoading = true;

          return providersService
            .createProvider(objToSent)
            .then((res) => {
              this.getProviders();
              this.dialogFormVisible = false;
              this.$router.push({
                name: "distributerProfile",
                params: { id: res.distributerId },
              });
              return res;
            })
            .catch((err) => {
              const errorMessage =
                this.$globalFunctions.errorMessageExtractor(err);
              this.$globalFunctions.popupMessageWrapper(
                errorMessage,
                "error",
                2000
              );
            })

            .finally(() => {
              this.providerCreateLoading = false;
            });
        } else {
          return false;
        }
      });
    },

    updateProviderAreas(providerId, areas) {
      return providersService.updateProviderAreas({ providerId, areas });
    },
    handlePagination(val) {
      const pageLoading = this.$loading();
      providersService
        .listAllProviders(val, this.filters)
        .then((response) => {
          this.providersList = response.providersList;
          this.pagination = response.pagination;

          this.$router.push({
            query: {
              page: response.pagination.nextPage
                ? response.pagination.nextPage - 1
                : response.pagination.totalPages,
            },
          });
        })
        .finally(() => {
          pageLoading.close();
        });
    },
    filtersChanged(key, value) {
      if (_.isEmpty(value + "")) {
        this.filters[key] = undefined;
      }
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.providers = { ...this.filters };
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));

      this.getProviders(1);

      this.$router.push({
        query: {
          page: 1,
        },
      });
      // this.getProducts(this.filters);
    },
  },
  watch: {
    lookups(newV) {
      if (newV && newV.areas && newV.areas.length > 0) {
        if (
          this.providesActivityTypeArray &&
          this.providesActivityTypeArray.length > 0
        ) {
          if (!(this.filters && this.filters.type)) {
            this.filters.type = this.providesActivityTypeArray[0].name;
          }
          this.getProviders();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.c-distributers-header {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.c-distributers-header__title {
  margin-right: auto;
  font-size: 24px;
  font-weight: bold;
}

[dir="rtl"] .c-distributers-header__title {
  margin-right: 0;
  margin-left: auto;
}

[dir="rtl"] .el-form-item::v-deep .el-form-item__content {
  margin-left: auto !important;
  margin-right: 200px !important;
}
</style>
